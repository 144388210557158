import React, { useState, useRef } from "react"
import { Modal, Button, Form, Row, Col } from "react-bootstrap"
import sendMail from "../services/send-mail"
import { PayPalButton } from "react-paypal-button-v2";
const config = require("../../config.json")

const ModalReservation = (props) => {

    const [total, setTotal] = useState(0);
    const [acompte, setAcompte] = useState(0);
    const [name, setName] = useState("");
    const [mail, setMail] = useState("");
    const [phone, setPhone] = useState("");
    const [n_enfants, setNEnfants] = useState(0);
    const [n_adultes, setNAdultes] = useState(0);
    const [cgv_checked, setCgvChecked] = useState(false);
    const formRef = useRef(null)

    const handleCancel = (e) => {
        setTotal(0)
        setAcompte(0)
        setNEnfants(0)
        setNAdultes(0)
        setCgvChecked(false)
        setName("")
        setMail("")
        setPhone("")
        props.onHide()
    }

    const handleChangeNEnfants = (e) => {
        const n_enfants = e.target.value
        const total = n_adultes * props.prestatoreserve.prix.adultes + n_enfants * props.prestatoreserve.prix.enfants
        setNEnfants(n_enfants)
        setTotal(total)
        setAcompte((0.3 * total).toFixed(2))
    }

    const handleChangeNAdultes = (e) => {
        const n_adultes = e.target.value
        const total = n_adultes * props.prestatoreserve.prix.adultes + n_enfants * props.prestatoreserve.prix.enfants
        setNAdultes(n_adultes)
        setTotal(total)
        setAcompte((0.3 * total).toFixed(2))
    }

    const handleCgvChange = (e) => {
        const cgv_checked = e.target.checked
        setCgvChecked(cgv_checked)
    }

    return (
        <Modal {...props} className="modal-reservation">
            <Modal.Header>
                <Modal.Title>
                    Réservation
                </Modal.Title>
            </Modal.Header>
            <Form ref={formRef}>
                <Modal.Body>

                    <div>
                        Merci de remplir tous les champs de manière à ce que je puisse vous contacter par la suite!
                    </div>

                    <hr />

                    <Form.Group controlId="name">
                        <Form.Label >Nom</Form.Label>
                        <Form.Control value={name} onChange={e => setName(e.target.value)} />
                    </Form.Group>

                    <Form.Group controlId="mail" >
                        <Form.Label>Mail</Form.Label>
                        <Form.Control value={mail} onChange={e => setMail(e.target.value)} />
                    </Form.Group>

                    <Form.Group controlId="phone">
                        <Form.Label>Téléphone</Form.Label>
                        <Form.Control value={phone} onChange={e => setPhone(e.target.value)} />
                    </Form.Group>


                    <hr />

                    <Form.Group>
                        <Form.Label>Date</Form.Label>
                        <Form.Control required as="select">
                            {props.prestatoreserve && props.prestatoreserve.nextDepartures ? props.prestatoreserve.nextDepartures.map((el, i) => <option key={i}>{el.date + " - " + el.time}</option>) : <></>}
                        </Form.Control>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Nombre d'adultes</Form.Label>
                        <Row>
                            <Col>
                                <Form.Control as="select" value={n_adultes} onChange={(e) => handleChangeNAdultes(e)} >
                                    {Array(20).fill().map((x, i) => <option key={i}>{i}</option>)}
                                </Form.Control>
                            </Col>
                            <Col className="text-center align-self-center">
                                <span>x {props.prestatoreserve && props.prestatoreserve.prix ? props.prestatoreserve.prix.adultes : 0} €</span>
                            </Col>
                        </Row>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Nombre d'enfants</Form.Label>
                        <Row>
                            <Col>
                                <Form.Control as="select" value={n_enfants} onChange={(e) => handleChangeNEnfants(e)} >
                                    {Array(20).fill().map((x, i) => <option key={i}>{i}</option>)}
                                </Form.Control>
                            </Col>
                            <Col className="text-center align-self-center">
                                <span>x {props.prestatoreserve && props.prestatoreserve.prix ? props.prestatoreserve.prix.enfants : 0} €</span>
                            </Col>
                        </Row>
                    </Form.Group>

                    <hr />

                    <Row>
                        <Col>Total</Col>
                        <Col className="text-center align-self-center">{total} €</Col>
                    </Row>
                    <Row>
                        <Col>Acompte (30% du total)</Col>
                        <Col className="text-center align-self-center">{acompte} €</Col>
                    </Row>

                    <hr />

                    <Row className="d-inline-flex align-items-center justify-content-start">
                        <Form.Check style={{ marginLeft: "1rem" }} checked={cgv_checked} onChange={(e) => handleCgvChange(e)} />
                        <span className="ml-2">J'accepte les <a href="/cgv/">Conditions Générales de Vente</a></span>
                    </Row>

                    {total > 0 && cgv_checked && !(name === "") && !(mail === "") && !(phone === "") && false &&
                        <>
                            <hr />
                            <Row className="align-self-center">
                                <Col className="text-center align-self-center">
                                    <PayPalButton
                                        type="submit"
                                        amount={0.3 * total}
                                        shippingPreference="NO_SHIPPING"
                                        currency={'EUR'}
                                        onSuccess={(details, data) => {
                                            const date_resa = formRef.current[3].value
                                            const prix_a_payer = total - acompte
                                            sendMail({
                                                name: name,
                                                phone: phone,
                                                reply_to: mail,
                                                mail: mail,
                                                date_resa: date_resa,
                                                rando_name: props.prestatoreserve.titre,
                                                nb_adultes: n_adultes,
                                                nb_enfants: n_enfants,
                                                prix_total: total,
                                                prix_paye: acompte,
                                                prix_a_payer: prix_a_payer,
                                            }, "LCQF_reservation").then((result) => {
                                                handleCancel()
                                            }, (error) => {
                                                console.log("ERROR")
                                                handleCancel()
                                            });
                                        }}
                                        options={{
                                            clientId: config.PAYPAL_CLIENT_ID,
                                            currency: config.PAYPAL_CURRENCY,
                                            locale: config.PAYPAL_LOCALE
                                        }}
                                    />
                                </Col>
                            </Row>
                        </>
                    }

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCancel}>Annuler</Button>
                </Modal.Footer>
            </Form>
        </Modal >
    );
}

export default ModalReservation