import moment from "moment"

const getAllNextDepartures = (nextDepartures) => {
    const allNextDepartures = nextDepartures.map(el => {
        const today = moment() + 0
        const time = moment(el.date + " " + el.time, "DD/MM/YYYY hh:mm")
        el.diff = time - today
        return el
    }).filter(el => el.diff >= 0)
        .sort((a, b) => {
            let timeA = moment(a.date + " " + a.time, "DD/MM/YYYY hh:mm")
            let timeB = moment(b.date + " " + b.time, "DD/MM/YYYY hh:mm")
            return timeA - timeB
        })
    return allNextDepartures
}

const getPartialNextDepartures = (allNextDepartures) => {
    const partialNextDepartures = allNextDepartures.filter(el => {
        const in2weeks = moment().add(2, 'weeks')
        const randoTime = moment(el.date + " " + el.time, "DD/MM/YYYY hh:mm")
        return randoTime.isBefore(in2weeks)
    })
    return partialNextDepartures
}

export { getAllNextDepartures, getPartialNextDepartures }