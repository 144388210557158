import React from "react"

const BadgeEtat = ({ etat, short = false }) => {

    switch (etat) {
        case 'garanti':
            console.log("garanti")
            return <span style={{ height: "1.3rem" }} className="d-inline-flex align-items-center justify-content-start badge badge-success"><span>{short ? "assuré" : "départ assuré"}</span></span>
        case 'non-garanti':
            console.log("non-garanti")
            return <span style={{ height: "1.3rem" }} className="d-inline-flex align-items-center justify-content-start badge badge-warning"><span>{short ? "non garanti" : "départ non garanti"}</span></span>
        case 'complet':
            console.log("complet")
            return <span style={{ height: "1.3rem" }} className="d-inline-flex align-items-center justify-content-start badge badge-danger"><span>complet</span></span>
        default:
            return <span />
    }

}

export default BadgeEtat